import { createAuth0 } from '@auth0/auth0-vue';

export const authParams = {
  audience: import.meta.env.VITE_APP_AUTH0_AUDIENCE,
  redirect_uri: import.meta.env.VITE_APP_URL + '/login',
  scope: 'openid profile email offline_access'
};

export const auth0 = createAuth0({
  domain: import.meta.env.VITE_APP_AUTH0_DOMAIN,
  clientId: import.meta.env.VITE_APP_AUTH0_CLIENT_ID,
  authorizationParams: authParams,
  cacheLocation: 'localstorage',
  useRefreshTokens: true,
  useRefreshTokensFallback: true
});
