<template>
  <PageTitle v-if="showPageTitle" @back="onClickBack">Confirmation</PageTitle>
  <div class="content">
    <div v-if="!queueItemId">
      <div class="card mb-3">
        <p class="mb-2">
          Check the below is all correct, and then click <strong>Submit</strong> below. Something
          not right? Go back and make any changes.
        </p>

        <div class="mb-3">
          <p class="font-w600">Reading Value</p>
          <p>{{ wizardStore.reading.value }}</p>
        </div>

        <div class="mb-3">
          <p class="font-w600">Reading Date</p>
          <p>{{ wizardStore.reading.submittedAt.toLocaleString() }}</p>
        </div>

        <div class="mb-3">
          <p class="font-w600">Meter Reset?</p>
          <p>{{ wizardStore.reading.isReset ? 'Yes' : 'No' }}</p>
        </div>

        <div class="mb-3" v-if="wizardStore.reading.units">
          <p class="font-w600">Override Units</p>
          <p>{{ wizardStore.reading.units }}</p>
        </div>

        <div class="mb-3" v-if="wizardStore.reading.factor">
          <p class="font-w600">Override Factor</p>
          <p>{{ wizardStore.reading.factor }}</p>
        </div>

        <div v-if="wizardStore.photoData.dataUrl">
          <div class="photo">
            <img :src="wizardStore.photoData.dataUrl" alt="photo" />
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="queueItemId && queueStore.queueItemById(queueItemId)?.status === 'submitting'"
      class="spinner-wrapper"
    >
      <SpinnerLogo>Submitting...</SpinnerLogo>
    </div>
    <div v-else-if="queueItemId && queueStore.queueItemById(queueItemId)?.status === 'submitted'">
      <div class="alert alert-success">Reading submitted successfully!</div>
    </div>
    <div v-else-if="queueItemId && queueStore.queueItemById(queueItemId)?.status === 'error'">
      <div v-if="queueStore.queueItemById(queueItemId)?.error" class="alert alert-danger">
        {{ queueStore.queueItemById(queueItemId)?.error }}
      </div>
      <div v-else class="alert alert-danger">There was a problem submitting this reading.</div>
    </div>
    <div
      v-else-if="
        queueItemId && queueStore.queueItemById(queueItemId)?.status === 'pending' && offlineMessage
      "
      class="alert alert-warning"
    >
      Your device is not currently connected to the internet. This reading will be saved for later.
      You can view saved readings in the "Readings Queue" section.
    </div>
    <div v-else>
      <div class="alert alert-danger">Something went wrong.</div>
    </div>
  </div>

  <FooterBox v-if="showPageTitle" @next="createReading">Submit</FooterBox>
  <FooterBox v-if="finished" @next="$router.push({ name: 'select' })">Submit Another</FooterBox>
</template>
<script lang="ts" setup>
import { computed, ref, onBeforeMount, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import PageTitle from '@/components/PageTitle.vue';
import FooterBox from '@/components/FooterBox.vue';
import SpinnerLogo from '@/components/SpinnerLogo.vue';
import type { WizardFullState } from '@/stores/queue';
import { useStore as useQueueStore } from '@/stores/queue';
import { useStore as useWizardStore } from '@/stores/wizard';
import { useStore as useUtilStore } from '@/stores/util';

const queueStore = useQueueStore();
const utilStore = useUtilStore();
const wizardStore = useWizardStore();
const $router = useRouter();

const loading = ref(false);
const offlineMessage = ref(false);
const queueItemId = ref<string | null>(null);

onBeforeMount(() => {
  if (!wizardStore.selectedAccount) {
    $router.push({ name: 'select' });
  }
});

const showPageTitle = computed(() => {
  if (!queueItemId.value) return true;

  const queueItem = queueStore.queueItemById(queueItemId.value);
  if (!queueItem) return true;

  if (
    queueItem.status === 'submitting' ||
    queueItem.status === 'submitted' ||
    queueItem.status === 'pending'
  )
    return false;

  return true;
});

const finished = computed(() => {
  if (!queueItemId.value) return false;

  const queueItem = queueStore.queueItemById(queueItemId.value);
  if (!queueItem) return false;

  return (
    queueItem.status === 'submitted' ||
    (queueItem.status === 'pending' && offlineMessage.value) ||
    queueItem.status === 'error'
  );
});

onMounted(() => {
  window.scrollTo(0, 0);
});

const createReading = async () => {
  if (!wizardStore.selectedAccount) {
    return;
  }

  queueItemId.value = queueStore.addWizardToQueue(wizardStore as WizardFullState);

  if (utilStore.isOffline) {
    offlineMessage.value = true;
  } else {
    loading.value = true;
    await queueStore.processQueueItem(queueItemId.value);
  }

  if (queueStore.queueItemById(queueItemId.value)?.status !== 'error') {
    wizardStore.$reset();
  }
};

const onClickBack = () => {
  $router.push({ name: 'photo' });
};
</script>
