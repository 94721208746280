import './assets/main.css';

import { createAuth0 } from '@auth0/auth0-vue';
import { auth0 } from './auth0.js';
import * as Sentry from '@sentry/vue';
import { createPinia } from 'pinia';
import { createApp } from 'vue';

import App from './App.vue';
import router from './router';

const app = createApp(App);

Sentry.init({
  app,
  dsn: 'https://cd5e9fa71a13572bc749ab1b7746c305@o4504219356037120.ingest.us.sentry.io/4508281255428096',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllText: false
    })
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/[a-z.]*app\.etainabl\.com/,
    /^https:\/\/[a-z-]*api\.etainabl\.com/,
    /^\//
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: import.meta.env.VITE_APP_ENV
});

app.use(createPinia());
app.use(router);
app.use(auth0);

app.mount('#app');
