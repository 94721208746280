import { defineStore } from 'pinia';

import { useStore as useAccountStore } from './account';
import { useStore as useAssetStore } from './asset';

export interface WizardState {
  accountId: string;
  assetId: string;
  overrideDate: boolean;
  overrideUnits: boolean;
  photoData: PhotoData;
  reading: Reading;
}

interface PhotoData {
  dataUrl: string;
  format: string;
}

interface Reading {
  value: number | null;
  units: string | null;
  factor: number | null;
  isReset: boolean;
  registerId: string;
  submittedAt: Date;
}

export const useStore = defineStore('wizard', {
  state: (): WizardState => {
    return {
      accountId: '',
      assetId: '',
      overrideDate: false,
      overrideUnits: false,
      photoData: {
        dataUrl: '',
        format: ''
      },
      reading: {
        value: null,
        units: null,
        factor: null,
        isReset: false,
        registerId: '1',
        submittedAt: new Date()
      }
    };
  },
  actions: {
    setPhotoData(data: PhotoData) {
      this.photoData = data;
    }
  },
  getters: {
    selectedAccount: (state: WizardState): ETNAccount | undefined => {
      const accountStore = useAccountStore();

      return accountStore.getAccountById(state.accountId);
    },
    selectedAsset: (state: WizardState): ETNAsset | undefined => {
      const assetStore = useAssetStore();

      return assetStore.getAssetById(state.assetId);
    }
  }
});
