<template>
  <div class="content">
    <div class="d-flex justify-content-center align-items-center">
      <button class="btn btn-primary" @click.prevent="login">Login</button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useAuth0 } from '@auth0/auth0-vue';

const { loginWithRedirect } = useAuth0();

const login = () => {
  loginWithRedirect();
};
</script>
