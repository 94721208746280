<template>
  <div class="user-header d-flex align-items-center">
    <div class="flex-grow-1">
      <div class="user-name">{{ user?.name || 'Not Logged In' }}</div>
      <div class="user-company">
        {{ companyStore.company?.name || '' }}
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useAuth0 } from '@auth0/auth0-vue';
import { useStore as useCompanyStore } from '@/stores/company';
import { onMounted } from 'vue';

const { user } = useAuth0();
const companyStore = useCompanyStore();

onMounted(async () => {
  console.log(user.value);
  if (user.value) {
    await companyStore.getCompany(user.value['https://app.etainabl.com/companyId']);
  }
});
</script>
