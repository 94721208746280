<template>
  <PageTitle hide-back>Readings Queue</PageTitle>

  <div class="content">
    <div class="card mb-3">
      <p class="font-w600 mb-2">Pending Readings</p>
      <div v-if="pendingQueue.length === 0">
        <div class="alert alert-success">No pending readings</div>
      </div>

      <div v-else>
        <ReadingsQueueItem
          v-for="(item, idx) in pendingQueue"
          :key="item.id"
          :class="{ 'mb-2': idx + 1 !== pendingQueue.length }"
          :id="item.id"
          @click="() => $router.push(`/queue/${item.id}`)"
        />
      </div>
    </div>
    <div class="card">
      <p class="font-w600 mb-2">Recently Submitted Readings</p>
      <div v-if="submittedQueue.length === 0">
        <div class="alert alert-warning">No submitted readings</div>
      </div>

      <div v-else>
        <ReadingsQueueItem
          v-for="(item, idx) in submittedQueue.slice(0, 20)"
          :key="item.id"
          :class="{ 'mb-2': idx + 1 !== submittedQueue.slice(0, 20).length }"
          :id="item.id"
          @click="() => $router.push(`/queue/${item.id}`)"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import type { QueueItem } from '@/stores/queue';
import { useStore as useQueueStore } from '@/stores/queue';
import PageTitle from '@/components/PageTitle.vue';
import ReadingsQueueItem from '@/components/ReadingsQueueItem.vue';

const queueStore = useQueueStore();
const $router = useRouter();

const pendingQueue = computed(() => {
  const queueClone = [...queueStore.queue];
  const queue = queueClone.filter((item) => item.status === 'pending');
  return queue.sort((a, b) => new Date(b.addedAt).getTime() - new Date(a.addedAt).getTime());
});

const submittedQueue = computed(() => {
  const queueClone = [...queueStore.queue];
  const queue = queueClone.filter((item) => item.status !== 'pending');
  return queue.sort((a, b) => new Date(b.addedAt).getTime() - new Date(a.addedAt).getTime());
});
</script>
