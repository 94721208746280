<template>
  <div v-if="assets.length > 0">
    <select
      class="form-control"
      type="text"
      @input="onSelect"
      :value="wizardStore.selectedAsset?._id"
    >
      <option selected>Select an asset</option>
      <option v-for="asset in assets" :key="asset._id" :value="asset._id">
        {{ asset.siteName }}{{ asset.distance != null ? ` [${asset.distance.toFixed(1)} mi]` : '' }}
      </option>
    </select>
  </div>
  <div v-else-if="assets.length === 0">
    <div class="alert alert-warning">No assets found</div>
  </div>
</template>
<script lang="ts" setup>
import { useStore as useWizardStore } from '@/stores/wizard';
import { onMounted } from 'vue';

const emit = defineEmits(['select']);

const wizardStore = useWizardStore();

defineProps<{
  assets: ETNAsset[];
}>();

const onSelect = (e: Event) => {
  emit('select', (e.target as HTMLSelectElement).value);
};
</script>
