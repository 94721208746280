import { defineStore } from 'pinia';
import { mande } from 'mande';

import { auth0 } from '../auth0.js';

export const initApi = async () => {
  const token = await auth0.getAccessTokenSilently();

  return mande(`${import.meta.env.VITE_APP_API_URL}/util`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const useStore = defineStore('util', {
  state: (): { units: ETNUnit[]; loading: boolean; isOffline: boolean } => {
    return {
      units: [],
      isOffline: false,
      loading: false
    };
  },
  actions: {
    async listUnits() {
      try {
        this.loading = true;

        const api = await initApi();

        const response = (await api.get('units')) as ETNUnit[];

        this.units = response;

        this.loading = false;
        return this.units;
      } catch (e) {
        this.loading = false;
        return e;
      }
    }
  }
});
