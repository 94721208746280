<template>
  <div v-if="accounts.length > 0">
    <select class="form-control" @input="onSelect" :value="wizardStore.selectedAccount?._id">
      <option value="" selected>Select a meter</option>
      <optgroup
        v-for="util in types.filter((t) =>
          filteredAccounts.some((a) => a.type === t.toLowerCase())
        )"
        :key="util"
        :label="util"
      >
        <option
          v-for="account in filteredAccounts.filter((a) => a.type === util.toLowerCase())"
          :key="account._id"
          :value="account._id"
        >
          {{ account.meterPointNumber ? `[${account.meterPointNumber}] ` : ''
          }}{{ account.meterSerialNumber ? `[${account.meterSerialNumber}] ` : ''
          }}{{ account.parentAccountId ? '(Sub)' : '' }} {{ account.name }}
        </option>
      </optgroup>
    </select>
    <!-- checkbox to show/hide sub-meters -->
    <div class="mt-1">
      <input
        class="mr-1"
        type="checkbox"
        id="showSubMeters"
        v-model="hideSubMeters"
        @input="onClickHideSubMeters"
      />
      <label class="font-w600" for="showSubMeters">Hide sub-meters</label>
    </div>
  </div>
  <div v-else-if="accounts.length === 0">
    <div class="alert alert-warning">No accounts found</div>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useStore as useWizardStore } from '@/stores/wizard';

const emit = defineEmits(['select']);

const wizardStore = useWizardStore();

const props = defineProps<{
  accounts: ETNAccount[];
}>();

const types = ref<string[]>(['Electricity', 'Gas', 'Water', 'Heating', 'Cooling']);
const hideSubMeters = ref(false);

const filteredAccounts = computed(() => {
  return hideSubMeters.value ? props.accounts.filter((a) => !a.parentAccountId) : props.accounts;
});

const onClickHideSubMeters = () => {
  const currentAccount = props.accounts.find((a) => a._id === wizardStore.selectedAccount?._id);

  if (currentAccount && currentAccount.parentAccountId) {
    emit('select', null);
  }
};

const onSelect = (e: Event) => {
  emit('select', (e.target as HTMLSelectElement).value);
};
</script>
