<template>
  <PageTitle @back="onClickBack">Enter Reading</PageTitle>
  <div v-if="wizardStore.selectedAccount" class="content">
    <div class="card mb-3">
      <div class="d-flex">
        <div class="flex-grow-1">
          <p class="font-w600 mb-1">Reading</p>
          <input
            v-model="wizardStore.reading.value"
            class="form-control mb-1"
            type="number"
            step="1"
            min="0"
          />
        </div>
        <div class="ml-3" v-if="isMultiRegister">
          <p class="font-w600 mb-1 mr-1">Register</p>
          <select
            id="registerId"
            v-model="wizardStore.reading.registerId"
            class="form-control"
            placeholder="Default (kwh)"
          >
            <option
              v-for="register in registerOptions"
              :key="register.value"
              :value="register.value"
            >
              {{ register.label }}
            </option>
          </select>
        </div>
      </div>
      <p class="small">
        Value must be greater than the most recent reading (see below for recent readings).
      </p>
    </div>

    <div class="card mb-3">
      <div class="d-flex align-items-center">
        <div class="flex-grow-1">
          <p class="font-w600">Meter Reset</p>
          <p class="small">Has this meter reset since its last reading? (Ignore if unsure)</p>
        </div>
        <div class="ml-3">
          <label
            for="isReset"
            class="etn-checkbox"
            :class="{ 'etn-checkbox--checked': wizardStore.reading.isReset }"
          >
            <input
              id="isReset"
              v-model="wizardStore.reading.isReset"
              type="checkbox"
              class="mb-0"
            />
          </label>
        </div>
      </div>
    </div>

    <div class="card mb-3">
      <div class="card-collapsable-title" @click="onToggleDateOverride">
        <p class="font-w600">Override Date</p>
        <ChevronRightIcon />
      </div>
      <div class="card-collapsable-content" :class="{ show: wizardStore.overrideDate }">
        <div class="d-flex mt-1">
          <div class="flex-grow-1 mr-2">
            <input type="date" class="form-control" @input="onChangeDate" v-model="dateOverride" />
          </div>
          <div>
            <input type="time" class="form-control" @input="onChangeDate" v-model="timeOverride" />
          </div>
        </div>
      </div>
    </div>

    <div class="card mb-3">
      <div class="card-collapsable-title" @click="onToggleUnitsOverride">
        <p class="font-w600">Override Units/Factor</p>
        <ChevronRightIcon />
      </div>
      <div class="card-collapsable-content" :class="{ show: wizardStore.overrideUnits }">
        <p class="small mb-2">
          By default this reading will use the meter's units ({{
            wizardStore.selectedAccount.meterUnits
          }}) and factor ({{ wizardStore.selectedAccount.factor || 1 }}). This can be overridden
          here but ensure you understand the implications.
        </p>
        <p class="font-w600 mb-1">Units</p>
        <select
          id="units"
          v-model="wizardStore.reading.units"
          class="form-control mb-2"
          placeholder="123"
        >
          <option v-for="option in unitOptions" :key="String(option.value)" :value="option.value">
            {{ option.label }}
          </option>
        </select>
        <p class="font-w600 mb-1">Factor</p>
        <input
          id="factor"
          v-model="wizardStore.reading.factor"
          class="form-control"
          :placeholder="`Meter Factor (${wizardStore.selectedAccount.factor || 1})`"
        />
      </div>
    </div>

    <div class="card mb-3">
      <p class="font-w600 mb-1">Recent meter readings</p>
      <div>
        <table v-if="!readingsStore.loading && readingsStore.readings.length > 0">
          <thead>
            <tr>
              <th>Submitted At</th>
              <th>Value</th>
              <th v-if="isMultiRegister">Reg.</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="reading in readingsStore.readings" :key="reading._id">
              <td>{{ reading.submittedAt }}</td>
              <td>{{ reading.value }}</td>
              <td v-if="isMultiRegister">{{ reading.registerId }}</td>
            </tr>
          </tbody>
        </table>
        <div
          v-else-if="
            !readingsStore.loading && readingsStore.readings.length === 0 && utilStore.isOffline
          "
        >
          <div class="alert alert-warning">
            Recent readings could not be loaded, as you are not connected to the internet.
          </div>
        </div>
        <div
          v-else-if="
            !readingsStore.loading && readingsStore.readings.length === 0 && !utilStore.isOffline
          "
        >
          <div class="alert alert-warning">No readings</div>
        </div>
        <div v-else class="spinner-wrapper">
          <SpinnerLogo />
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="!wizardStore.selectedAccount && !readingsStore.loading">
    <div class="alert alert-warning">
      No meter selected. <router-link :to="{ name: 'select' }">Go back</router-link>
    </div>
  </div>
  <div v-else>
    <SpinnerLogo />
  </div>

  <FooterBox v-if="wizardStore.selectedAccount && wizardStore.reading.value" @next="onClickNext" />
</template>
<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import PageTitle from '@/components/PageTitle.vue';
import SpinnerLogo from '@/components/SpinnerLogo.vue';
import FooterBox from '@/components/FooterBox.vue';
import { useStore as useReadingStore } from '@/stores/reading';
import { useStore as useUtilStore } from '@/stores/util';
import { useStore as useWizardStore } from '@/stores/wizard';
import ChevronRightIcon from '@/components/icons/ChevronRightIcon.vue';

const emit = defineEmits(['submit', 'back']);

defineProps<{
  subMeterId?: string;
}>();

const $router = useRouter();
const readingsStore = useReadingStore();
const utilStore = useUtilStore();
const wizardStore = useWizardStore();

const dateOverride = ref<string | null>(null);
const timeOverride = ref<string | null>(null);

onMounted(() => {
  window.scrollTo(0, 0);

  if (!wizardStore.selectedAccount) {
    return $router.push({ name: 'select' });
  }

  utilStore.listUnits();

  readingsStore.listReadings(wizardStore.selectedAccount._id);
});

const unitOptions = computed(() => {
  if (!wizardStore.selectedAccount) return [];

  const meterUnits = utilStore.units.find(
    (u) => u.value === wizardStore.selectedAccount?.meterUnits
  );

  return [
    {
      label: `Meter Units (${meterUnits ? meterUnits.name : wizardStore.selectedAccount.meterUnits})`,
      value: null
    },
    ...utilStore.units
      .filter(
        (u) => !wizardStore.selectedAccount || u.types.includes(wizardStore.selectedAccount.type)
      )
      .map((u) => ({ label: u.name, value: u.value }))
  ];
});

const registerOptions = computed(() => {
  if (!wizardStore.selectedAccount) return [];

  return [
    { label: '1 (Default)', value: '1' },
    ...(wizardStore.selectedAccount.registerIds || []).map((registerId) => ({
      label: registerId,
      value: registerId
    }))
  ];
});

const isMultiRegister = computed(() => {
  if (!wizardStore.selectedAccount) return false;

  return (
    wizardStore.selectedAccount.registerIds.length > 1 ||
    (wizardStore.selectedAccount.registerIds.length === 1 &&
      wizardStore.selectedAccount.registerIds[0] !== '1')
  );
});

const onToggleDateOverride = () => {
  wizardStore.overrideDate = !wizardStore.overrideDate;

  const now = new Date().toISOString();

  const [date, time] = now.split('T');

  dateOverride.value = date;
  timeOverride.value = time.slice(0, 8);
};

const onToggleUnitsOverride = () => {
  wizardStore.overrideUnits = !wizardStore.overrideUnits;

  wizardStore.reading.factor = null;
  wizardStore.reading.units = null;
};

const onChangeDate = () => {
  wizardStore.reading.submittedAt = new Date(`${dateOverride.value}T${timeOverride.value}.000Z`);
};

utilStore.$subscribe((mutation, state) => {
  if (!state.isOffline) {
    readingsStore.listReadings(wizardStore.accountId);
  }
});

const onClickNext = () => {
  $router.push({ name: 'photo' });
};

const onClickBack = () => {
  $router.push({ name: 'select' });
};
</script>
