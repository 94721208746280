<template>
  <div class="page-title font-w600">
    <a href="#" @click="emit('back')" class="back" v-if="!hideBack">
      <ChevronLeftIcon />
    </a>
    <slot></slot>
  </div>
</template>
<script lang="ts" setup>
import ChevronLeftIcon from './icons/ChevronLeftIcon.vue';

const emit = defineEmits(['back']);

defineProps<{
  hideBack?: boolean;
}>();
</script>
<style>
.page-title {
  position: fixed;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;

  width: 100%;
  height: var(--etn-c-title-height);

  padding-top: calc(var(--etn-c-header-height) + var(--etn-c-padding));
  padding-bottom: var(--etn-c-padding);
  text-align: center;

  background: var(--color-background);
}

.is-alert .page-title {
  padding-top: calc(var(--etn-c-header-height) + var(--etn-c-padding) + var(--alert-height));
}

.back {
  position: absolute;
  left: var(--etn-c-padding);

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background-color: var(--color-background-soft);

  height: 34px;
  width: 34px;
}

.back svg {
  fill: var(--color-text);
  width: 12px;
  height: 12px;
}
</style>
