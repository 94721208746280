<template>
  <PageTitle @back="onClickBack">Reading Details</PageTitle>

  <div class="content">
    <div v-if="!isForceSubmitting">
      <div class="card mb-3">
        <ReadingsQueueItem :id="item.id" />
        <div v-if="item.error" class="alert alert-danger mt-2">{{ item.error }}</div>
      </div>
      <div class="card mb-3">
        <p class="font-w600 mb-2">Reading Details</p>
        <ReadingSummary :reading="item.state.reading" :photoData="item.state.photoData" />
      </div>
      <div class="card mb-3">
        <p class="font-w600 mb-2">Meter Details</p>
        <MeterSummary :item="item.state" />
      </div>
      <div
        class="btn btn-primary btn-block"
        v-if="item.status !== 'submitted'"
        @click="onClickSubmit"
      >
        Force Submit
      </div>
    </div>

    <div v-else-if="item.status === 'submitting'" class="spinner-wrapper">
      <SpinnerLogo>Submitting...</SpinnerLogo>
    </div>
    <div v-else-if="item.status === 'submitted'">
      <div class="alert alert-success">Reading submitted successfully!</div>
    </div>
    <div v-else-if="item.status === 'error'">
      <div class="alert alert-danger">There was a problem submitting this reading.</div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import type { QueueItem } from '@/stores/queue';
import { useStore as useQueueStore } from '@/stores/queue';
import PageTitle from '@/components/PageTitle.vue';
import MeterSummary from '@/components/MeterSummary.vue';
import ReadingSummary from '@/components/ReadingSummary.vue';
import ReadingsQueueItem from '@/components/ReadingsQueueItem.vue';
import SpinnerLogo from '@/components/SpinnerLogo.vue';

const queueStore = useQueueStore();
const $router = useRouter();
const $route = useRoute();

const isForceSubmitting = ref(false);

const item = queueStore.queueItemById(String($route.params.id)) as QueueItem;

const onClickSubmit = async () => {
  isForceSubmitting.value = true;

  await queueStore.processQueueItem(item.id);
};

const onClickBack = () => {
  $router.back();
};
</script>
