<template>
  <PageTitle @back="onClickBack">Submit Photo (Optional)</PageTitle>
  <div class="content">
    <div class="card">
      <div v-if="!wizardStore.photoData.dataUrl">
        <p class="mb-2">
          Attach a photo to this reading by taking a photo or selecting an existing photo.
        </p>
        <button class="btn btn-primary btn-block" @click="takePhoto">Take Photo</button>
      </div>

      <div v-else>
        <p class="font-w600 mb-2">Photo preview</p>
        <div class="photo mb-3">
          <img :src="wizardStore.photoData.dataUrl" alt="photo" />
        </div>

        <button class="btn btn-primary btn-block" @click="takePhoto">Retake</button>
        <button class="btn btn-grey btn-block" @click="onClickClear">Clear</button>
      </div>
    </div>

    <FooterBox @next="onClickNext" />
  </div>
</template>
<script lang="ts" setup>
import { Camera, CameraResultType } from '@capacitor/camera';
import type { Photo } from '@capacitor/camera';
import { ref, onMounted } from 'vue';
import type { Ref } from 'vue';
import { useRouter } from 'vue-router';

import { useStore as useWizardStore } from '@/stores/wizard';

import FooterBox from '@/components/FooterBox.vue';
import PageTitle from '@/components/PageTitle.vue';

const emit = defineEmits(['submit', 'back']);

const wizardStore = useWizardStore();
const $router = useRouter();

const photo: Ref<Photo | null> = ref(null);

onMounted(() => {
  window.scrollTo(0, 0);
});

const takePhoto = async () => {
  photo.value = await Camera.getPhoto({
    quality: 90,
    allowEditing: true,
    resultType: CameraResultType.DataUrl
  });

  if (photo.value.dataUrl) {
    wizardStore.setPhotoData({
      format: photo.value.format,
      dataUrl: photo.value.dataUrl
    });
  }
};

const onClickClear = () => {
  wizardStore.setPhotoData({
    dataUrl: '',
    format: ''
  });

  photo.value = null;
};

const onClickNext = async () => {
  $router.push({ name: 'confirmation' });
};

const onClickBack = () => {
  $router.push({ name: 'reading' });
};
</script>
<style>
.photo {
  width: 100%;
  height: auto;
}
.photo img {
  width: 100%;
  max-height: 350px;
  object-fit: contain;
}
</style>
