import { defineStore } from 'pinia';
import { mande } from 'mande';

import { auth0 } from '../auth0.js';

export const initApi = async () => {
  const token = await auth0.getAccessTokenSilently();

  return mande(`${import.meta.env.VITE_APP_API_URL}/companies`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

interface ETNCompany {
  name: string;
}

export const useStore = defineStore('company', {
  // arrow function recommended for full type inference
  state: (): { company: ETNCompany } => {
    return {
      company: {
        name: 'N/A'
      }
    };
  },
  actions: {
    async getCompany(id: string) {
      try {
        const api = await initApi();
        this.company = await api.get(id);
      } catch (e) {
        return e;
      }
    }
  }
});
