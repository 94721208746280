import { mande, type MandeError } from 'mande';
import { defineStore } from 'pinia';
import { toRaw } from 'vue';
import { dataURLtoFile } from '@/utils';

import { useStore as useReadingStore } from './reading';
import type { WizardState } from './wizard';

export interface WizardFullState extends WizardState {
  selectedAccount: ETNAccount;
  selectedAsset: ETNAsset;
}

export interface QueueItem {
  id: string;
  status: 'pending' | 'submitting' | 'submitted' | 'error';
  error: string | null;
  photoS3Key: string | null;
  addedAt: string;
  state: WizardFullState;
}

interface QueueState {
  queue: QueueItem[];
}

interface PhotoUploadResponse {
  ok: boolean;
  message?: string;
}

export const useStore = defineStore('queue', {
  state: (): QueueState => {
    return {
      queue: []
    };
  },
  getters: {
    queueItemById:
      (state: QueueState) =>
      (id: string): QueueItem | undefined => {
        return state.queue.find((item) => item.id === id);
      },
    submittingQueueItems: (state: QueueState) => {
      return state.queue.filter((item) => item.status === 'submitting');
    }
  },
  actions: {
    addWizardToQueue(wizard: WizardFullState) {
      const id = Math.random().toString(36).substring(7);

      console.log('wizard', wizard);
      console.log('raw wizard', toRaw(wizard));

      const fullWizard = {
        accountId: wizard.accountId,
        assetId: wizard.assetId,
        overrideDate: wizard.overrideDate,
        overrideUnits: wizard.overrideUnits,
        photoData: toRaw(wizard.photoData),
        reading: toRaw(wizard.reading),
        selectedAccount: toRaw(wizard.selectedAccount),
        selectedAsset: toRaw(wizard.selectedAsset)
      };
      console.log('full wizard', fullWizard);

      this.queue.push({
        id,
        status: 'pending',
        addedAt: new Date().toISOString(),
        photoS3Key: null,
        error: null,
        state: fullWizard
      });

      return id;
    },
    async processPendingQueueItems(lastId?: string) {
      const pendingItems = this.queue.filter((item) => item.status === 'pending');

      // Process one at a time
      if (pendingItems.length > 0) {
        console.log(`Processing ${pendingItems[0].id}`, pendingItems[0]);
        if (lastId === pendingItems[0].id) {
          console.log('Tried to process the same item twice');
          return;
        }
        await this.processQueueItem(pendingItems[0].id);

        // Process the next one
        this.processPendingQueueItems(pendingItems[0].id);
      }
    },
    async uploadPhoto(queueId: string): Promise<PhotoUploadResponse> {
      const queueItem = this.queue.find((item) => item.id === queueId);
      if (!queueItem) {
        return { ok: false, message: 'No queue item found' };
      }

      const photoData = queueItem.state.photoData;
      if (!photoData.dataUrl || !photoData.format) {
        return { ok: false, message: 'No photo taken' };
      }

      const readingStore = useReadingStore();

      // Get the upload URL
      const photoUploadData = await readingStore.getPhotoUploadUrl(photoData.format);
      if (!photoUploadData?.uploadUrl) {
        return { ok: false, message: 'Could not get upload url' };
      }

      try {
        const photoBinary = dataURLtoFile(photoData.dataUrl, 'photo');

        await fetch(photoUploadData.uploadUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': `image/${photoData.format}`
          },
          body: photoBinary
        });

        queueItem.photoS3Key = photoUploadData.s3Key;

        return { ok: true };
      } catch (e) {
        return {
          ok: false
        };
      }
    },
    async processQueueItem(queueId: string) {
      const queueItem = this.queue.find((item) => item.id === queueId);
      if (!queueItem) {
        return false;
      }

      try {
        queueItem.status = 'submitting';

        // Upload the photo
        const photoUploadResponse = await this.uploadPhoto(queueId);
        if (!photoUploadResponse.ok) {
          console.log('Error uploading photo', photoUploadResponse.message);
        }

        // Submit the reading
        const readingStore = useReadingStore();
        const reading = queueItem.state.reading;

        const etnReading: Omit<ETNReading, '_id'> = {
          registerId: reading.registerId,
          value: reading.value!,
          submittedAt: new Date(reading.submittedAt).toISOString(),
          type: 'A',
          isReset: reading.isReset,
          units: reading.units,
          factor: reading.factor,
          s3Key: photoUploadResponse.ok && queueItem.photoS3Key ? queueItem.photoS3Key : undefined,
          source: 'app',
          accountId: queueItem.state.selectedAccount._id,
          companyId: queueItem.state.selectedAccount.companyId,
          entityId: queueItem.state.selectedAccount.entityId
        };

        await readingStore.submitReading(etnReading);

        queueItem.status = 'submitted';

        console.log('Submitting reading', etnReading);
      } catch (e) {
        console.error('Error processing queue item', e);
        queueItem.status = 'error';
        queueItem.error = (e as Error).message;

        if ((e as MandeError).response) {
          const mandeError = e as MandeError;
          console.error('mandeError response', mandeError.response);
          console.error('mandeError body', mandeError.body);

          if (mandeError.body && mandeError.body.message) {
            if (
              mandeError.body.message === 'Form validation error' &&
              !Array.isArray(mandeError.body.formMessages)
            ) {
              queueItem.error = `${mandeError.body.message}: ${mandeError.body.formMessages?.value}`;
            } else if (
              mandeError.body.message === 'Form validation error' &&
              Array.isArray(mandeError.body.formMessages)
            ) {
              queueItem.error = `${mandeError.body.message}: ${mandeError.body.formMessages.map((m: { value: string }) => m.value || m).join(', ')}`;
            } else {
              queueItem.error = `${mandeError.body.message}`;
            }
          } else if (mandeError.body?.error?.errors) {
            if (Array.isArray(mandeError.body.error.errors)) {
              const errors = Object.values(mandeError.body.error.errors).map(
                (err) => (err as { message: string }).message
              );
              queueItem.error = `${mandeError.body.error._message}: ${errors.join(', ')}`;
            } else {
              queueItem.error = `${mandeError.body.error._message}: ${mandeError.body.error.errors}`;
            }
          }
        }
      }
    }
  }
});
