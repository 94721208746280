<template>
  <div class="header-wrapper">
    <div class="header">
      <div class="flex-grow-1">
        <div class="d-flex align-items-center">
          <div class="menu-icon mr-2" @click="showSideMenu = !showSideMenu">
            <BarsIcon v-if="!showSideMenu" />
            <XMarkIcon v-else />
          </div>
          <div class="logo">
            <img src="@/assets/icon.svg" alt="Logo" />
          </div>
        </div>
      </div>
      <div v-if="user?.['https://app.etainabl.com/companyId']" class="user">
        <UserBox />
      </div>
    </div>
    <div v-if="queueActivity" class="header-alert alert alert-info">Submitting readings...</div>
  </div>

  <div class="side-menu" :class="{ show: showSideMenu }">
    <div v-if="isAuthenticated" class="side-menu-item" @click="onClickMenuItem('select')">
      <PlusIcon />
      <div>Submit a new reading</div>
    </div>
    <div v-if="isAuthenticated" class="side-menu-item" @click="onClickMenuItem('queue')">
      <HourglassClockIcon />
      <div>Pending readings</div>
    </div>
    <div class="side-menu-item mt-5">
      <RightFromBracketIcon />
      <div @click.prevent="onClickLogout">Logout</div>
    </div>
  </div>

  <div v-if="loading" class="content">
    <div class="spinner-wrapper">
      <SpinnerLogo>Logging in...</SpinnerLogo>
    </div>
  </div>

  <div v-else :class="{ 'is-alert': queueActivity }">
    <router-view />
  </div>
</template>

<script setup lang="ts">
import { Network } from '@capacitor/network';
import { useAuth0 } from '@auth0/auth0-vue';
import localforage from 'localforage';
import { ref, onBeforeMount, onBeforeUnmount, toRaw, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import UserBox from '@/components/UserBox.vue';
import BarsIcon from '@/components/icons/BarsIcon.vue';
import HourglassClockIcon from '@/components/icons/HourglassClockIcon.vue';
import PlusIcon from '@/components/icons/PlusIcon.vue';
import RightFromBracketIcon from '@/components/icons/RightFromBracketIcon.vue';
import SpinnerLogo from '@/components/SpinnerLogo.vue';
import XMarkIcon from '@/components/icons/XMarkIcon.vue';

import { useStore as useQueueStore } from '@/stores/queue';
import { useStore as useUtilStore } from '@/stores/util';

const { isAuthenticated, user, logout } = useAuth0();

const utilStore = useUtilStore();
const queueStore = useQueueStore();
const $router = useRouter();

const showSideMenu = ref(false);
const loading = ref(true);

onBeforeMount(async () => {
  Network.addListener('networkStatusChange', (status) => {
    utilStore.isOffline = !status.connected;

    if (status.connected) {
      queueStore.processPendingQueueItems();
    }
  });

  // Sync store offline status with actual network status
  utilStore.isOffline = !(await Network.getStatus()).connected;

  if (user.value) {
    const queue = (await localforage.getItem(
      `queue-${user.value['https://app.etainabl.com/companyId']}`
    )) as string | null;
    if (queue) queueStore.$patch(JSON.parse(queue));
  }

  loading.value = false;
});

onBeforeUnmount(() => {
  Network.removeAllListeners();
});

const onClickMenuItem = (route: string) => {
  showSideMenu.value = false;
  $router.push({ name: route });
};

const onClickLogout = async () => {
  await logout();
  showSideMenu.value = false;
  $router.push({ name: 'login' });
};

const queueActivity = queueStore.submittingQueueItems.length;

queueStore.$subscribe(
  (mutation, state) => {
    console.log(mutation);
    console.log(state);

    if (!user.value) return;

    localforage.setItem(
      `queue-${user.value['https://app.etainabl.com/companyId']}`,
      JSON.stringify({
        queue: state.queue.map((item) => toRaw(item))
      })
    );
  },
  { flush: 'sync' }
);
</script>
