<template>
  <div
    class="d-flex flex-column justify-content-center align-items-center etn-spinner-wrapper"
    :class="spinnerClass"
  >
    <div class="etn-spinner mb-2">
      <div class="left"></div>
      <div class="right"></div>
    </div>
    <div class="etn-spinner-text">
      <slot>Loading...</slot>
    </div>
  </div>
</template>
<script lang="ts">
export default {
  props: {
    size: {
      type: String,
      default: 'lg'
    },
    variant: {
      type: String,
      default: 'primary'
    },
    center: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    spinnerClass() {
      return {
        [`spinner-${this.size}`]: this.size !== 'md',
        spinner: this.size === 'md',
        [`spinner-${this.variant}`]: this.variant
      };
    }
  }
};
</script>
<style>
.etn-spinner-wrapper {
  position: relative;
  z-index: 100;
}
.etn-spinner-text {
  color: #3c5c61;
  opacity: 1;
  font-weight: 600;

  text-align: center;

  animation: spinner-text 3s infinite;
  animation-delay: 1200ms;
  font-size: 16px;
}

.etn-spinner,
.etn-spinner * {
  box-sizing: border-box;
}

.etn-spinner {
  position: relative;
  width: 70px;
  height: 50px;
  clip-path: circle(50px);
}

.etn-spinner .left {
  position: absolute;
  opacity: 0;
  left: 0;
  bottom: 0;
  background-color: rgb(39, 58, 74);
  width: 15px;
  height: 50px;
  transform: skew(-30deg);
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 26%, 0 0);

  animation: etn-spinner-left 3s infinite;
  animation-delay: 200ms;
}

.etn-spinner .right {
  position: absolute;
  opacity: 0;
  left: 0;
  bottom: 0;
  background-color: rgb(101, 193, 152);
  width: 15px;
  height: 34px;
  transform: skew(30deg);
  clip-path: polygon(100% 0, 100% 0, 100% 100%, 0 100%, 0 38%);

  animation: etn-spinner-right 3s infinite;
}

@media (prefers-color-scheme: dark) {
  .etn-spinner-text {
    color: #f7f7f7;
  }

  .etn-spinner .right {
    background-color: #f7f7f7;
  }

  .etn-spinner .left {
    background-color: #f7f7f7;
  }
}

@keyframes etn-spinner-left {
  0% {
    opacity: 0;
    left: -30px;
    bottom: 30px;
    transform: rotate(-180deg) skew(-30deg);
  }
  10% {
    opacity: 1;
  }
  20% {
    left: 20px;
    bottom: 0;
    transform: rotate(0deg) skew(-30deg);
  }
  70% {
    left: 20px;
    bottom: 0;
    transform: rotate(0deg) skew(-30deg);
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    left: -20px;
    bottom: -20px;
    transform: rotate(-270deg) skew(-30deg);
  }
}

@keyframes etn-spinner-right {
  0% {
    opacity: 0;
    left: 70px;
    bottom: 30px;
    transform: rotate(-180deg) skew(30deg);
  }
  10% {
    opacity: 1;
  }
  20% {
    left: 40px;
    bottom: 0;
    transform: rotate(0deg) skew(30deg);
  }
  70% {
    left: 40px;
    bottom: 0;
    transform: rotate(0deg) skew(30deg);
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    left: 60px;
    bottom: -20px;
    transform: rotate(270deg) skew(30deg);
  }
}

@keyframes spinner-text {
  10% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
}
</style>
