<template>
  <main v-if="isAuthenticated">
    <router-view />
  </main>
  <LoginBox v-else />
</template>
<script lang="ts" setup>
import { useAuth0 } from '@auth0/auth0-vue';
import LoginBox from '@/components/LoginBox.vue';

const { isAuthenticated } = useAuth0();
</script>
