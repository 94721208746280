<template>
  <div class="d-flex justify-content-between align-items-center">
    <div class="overflow-hidden flex-grow-1">
      <div class="small text-muted font-w600">
        {{ new Date(item.addedAt).toLocaleString() }}
      </div>
      <div class="font-w600 font-size-h4 no-wrap-ellipsis">
        {{ item.state.selectedAccount.meterPointNumber }}
      </div>
      <div class="small font-w600 no-wrap-ellipsis">
        {{ item.state.selectedAsset.addressString }}
      </div>
    </div>
    <div class="text-right">
      <div class="font-w600 text-muted">
        {{ item.state.reading.value }}
      </div>
      <div class="font-w600 text-uppercase ml-2" :class="statusClass(item.status)">
        {{ item.status }}
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import type { QueueItem } from '@/stores/queue';
import { useStore as useQueueStore } from '@/stores/queue';

const queueStore = useQueueStore();

const props = defineProps<{
  id: QueueItem['id'];
}>();

const statusClass = (status: QueueItem['status']) => {
  return {
    'text-success': status === 'submitted',
    'text-danger': status === 'error' || status === 'submitting',
    'text-info': status === 'pending'
  };
};

const item = queueStore.queue.find((item) => item.id === props.id) as QueueItem;
</script>
